<template>
    <div class="order-table">
        <h3 class="mb-3">{{title}}</h3>
        <div class="row mb-2">
            <div class="col-sm-12 col-md-8">
                <div class="d-flex">
                    <label class="mr-2">
                        <b-form-select v-model="itemsPerPage" :options="pageOptions"></b-form-select>
                    </label>
                    <div>
                        <b-form-select @change="fetchOrders()" v-model="defaultStatus" :options="statusOptions"></b-form-select>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="">
                <label class="d-block">
                    <b-form-input v-model="searchQuery" @input="updateSearchQuery" type="search" placeholder="Search..." class="form-control form-control-sm"></b-form-input>
                </label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <is-loading v-if="isLoading" :box="true" />
                <div v-else class="order-table-wrap py-2">
                    <div class="row g-3">
                        <template v-for="(order, orIndex) in orders">
                            <div @click="$router.push({path: `/orders/${order.id}`})" :key="orIndex" class="col-12 col-md-6 col-lg-12">
                                <div class="ord-tb-tr p-4 p-lg-5">
                                    <div class="row align-items-lg-center gy-1">
                                        <div class="col-lg-4">
                                            <div class="ord-tb-td" v-if="order.product">
                                                <div class="mb-2">Number: <strong>#{{order.number}}</strong></div>
                                                <span class="mr-1">Project:</span> <strong>{{order.product.name}}</strong>
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="ord-tb-td">
                                                <span class="mr-1">Buyer:</span> <strong>{{order.buyer.first_name}} {{order.buyer.last_name}}</strong>
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="ord-tb-td">
                                                <span class="mr-1">Status:</span> <strong class="text-uppercase">{{order.status == 'delivered' ? 'In progress' : order.status }}</strong>
                                            </div>
                                        </div>
                                        <div class="col-lg-2">
                                            <div class="ord-tb-td text-lg-right">
                                                <span class="btn btn-sm btn-outline-success">view</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <p class="text-center bg-white py-4" v-if="!orders.length">No order to show</p>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col d-flex justify-content-center">
                <div class="">
                    <b-pagination v-model="currentPage" class="pagination-rounded mb-0"
                        :total-rows="pageData.total" :limit="5" :per-page="itemsPerPage"></b-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import _ from 'lodash';
import IsLoading from '@/components/IsLoading.vue';

export default {
    name: 'order-table',
    components:{
        IsLoading
    },
    data() {
        return {
            isLoading: false,
            defaultStatus: "active",
            searchQuery:"",
            pageOptions: [1, 10, 25, 50, 100],
            statusOptions: [
                { value: 'all', text: 'Show all' },
                { value: 'active', text: 'Active' },
                { value: 'delivered', text: 'In Progress' },
                { value: 'completed', text: 'Completed' },
                { value: 'cancelled', text: 'Cancelled' }
            ]
        };
    },
    props:{
        title:{
            type: String,
            default: "Available orders"
        },
    },
    watch: {
        "$route.query":"refreshQueryStatus"
    },
    computed:{
        pageData(){
            return this.$store.state.orderList.pageData
        },
        orders() {
            return this.pageData.data.filter((order) => this.defaultStatus == "all" 
                || (order.status == this.defaultStatus))
        },
        currentPage: {
            get() {
                return this.pageData.current_page
            },
            set(val) {
                if(!this.pageData.current_page || this.pageData.current_page == val) return
                this.fetchOrders(val)
            }
        },
        itemsPerPage: {
            get() {
                return +this.pageData.per_page || 10
            },
            set(val) {
                this.fetchOrders(1, val)
            }
        },
    },
    methods:{
        refreshQueryStatus(){
            if(this.$route.query.status){
                this.defaultStatus = this.$route.query.status
            }
            if(this.status){ this.defaultStatus = this.status}
        },
        updateSearchQuery: _.debounce(function(string) {
            this.fetchOrders()
        }, 2000),
        fetchOrders(page, per_page = null){
            let payload = { page: page || 1, per_page: per_page || this.itemsPerPage }
            if(this.defaultStatus != 'all'){ payload.status = this.defaultStatus }
            if(this.searchQuery){ payload.search = this.searchQuery }
            this.isLoading = true
            this.$store.dispatch("orderList/fetchOrders", payload)
            .then(response => this.isLoading = false)
        },
    },
    mounted(){
        this.refreshQueryStatus()
        this.fetchOrders()
    }

}
</script>

<style scoped>
.order-table .order-table-wrap .ord-tb-tr{
    background: #fff;
}
.order-table .order-table-wrap .ord-tb-tr:hover{
    background: rgb(141 141 141 / 13%);
    transform: all .5s ease-in-out;
    cursor: pointer;
}

</style>
